<template>
    <div>
        <div v-if="authUserPermission['nato-group-class-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header mt--4 mb--4">
                                <div class="row">
                                    <div class="col-3">
                                        <h3 class="mb-0">{{ tt('ngc') }}</h3>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_nato')" v-on:keyup="filter"/>                            
                                        </div>
                                    </div>
                                    <div class="col-3 text-right">
                                        <base-button size="sm" type="default" @click="create" v-if="authUserPermission['nato-group-class-create']">{{ tt('add_nato') }}</base-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" ref="singleTable" highlight-current-row @current-change="handleCurrentChange" v-if="!onLoad">
                                    <el-table-column :label="tt('nsc')" :prop="tt('nsc')" min-width="100px" sortable>
                                        <template v-slot="{row}">
                                            {{row.nsc}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('main_group')" :prop="tt('main_group')" min-width="150px" sortable>
                                        <template v-slot="{row}">
                                            {{row.main_group}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('description')" :prop="tt('description')" min-width="170px" sortable>
                                        <template v-slot="{row}">
                                            {{row.description}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="100">
                                        <template v-slot="{row}">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['nato-group-class-update'] || authUserPermission['nato-group-class-delete']">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                    <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['nato-group-class-update']">{{ tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['nato-group-class-delete']">{{ tt('delete') }}</el-dropdown-item>                                        
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <page-loading v-else/>
                            </div>
                            <!-- <div class="text-center" v-else>
                                <img src="/img/smartcat/data_is_empty.png" alt="Data Is Empty" width="400px">
                                <p class="text-muted">{{ tt('please_search_to_show_data') }}</p>
                            </div> -->
                            <div class="card-footer">                         
                                <div class="col text-right">
                                    {{ tt('page_info', {size: table.total, total: table.total}) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header mt--4">
                                <div class="row">
                                    <div class="col-4">
                                        <h3 class="mb-0">{{ tt('definition') }}</h3>
                                    </div>
                                </div>
                                <div class="row ml-0 mt-4" style="height: 398px">
                                    <tabs tabNavClasses="flex-sm-row">
                                        <tab-pane :title="tt('english')" class="mt-3">
                                            {{definition_eng}}
                                        </tab-pane>
                                        <tab-pane :title="tt('indonesia')" class="mt-3">
                                            {{definition_ind}}
                                        </tab-pane>
                                    </tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        
                        <label class="form-control-label">{{ tt('nsc') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('nsc')" :placeholder="tt('nsc')" v-model="natoGroupClass.nsc" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('description')" :placeholder="tt('description')" v-model="natoGroupClass.description" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('english_definition') }}</label>
                        <base-input :name="tt('english_definition')" :placeholder="tt('english_definition')" v-model="natoGroupClass.definition_eng" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('indonesia_definition') }}</label>
                        <base-input :name="tt('indonesia_definition')" :placeholder="tt('indonesia_definition')" v-model="natoGroupClass.definition_ind" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.showTableing || invalid">
                            <span v-if="btnSave.showTableing"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';

    export default {        
        data() {
            return {   
                currentRow: null,
                showTable: false,
                onLoad: true,
                loadTimeout: null,
                btnSave: {
                    showTableing: false
                },    
                form: {
                    title: this.tt('add_nato'),
                    show: false,
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: []
                },
                definition_eng: '',
                definition_ind: '',
                errorMessage: null,   
                natoGroupClass: {
                    
                },
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
                this.definition_eng = val.definition_eng;
                this.definition_ind = val.definition_ind;
            },
            rowClassName({ rowIndex }) {
                return 'table-success'
            },
            get() {
                let context = this;
                context.definition_eng = '';
                context.definition_ind = '';

                // if (this.table.search == '') {
                //     context.table.data = [];
                //     context.table.total = 0;
                //     this.showTable = false;
                // } else {
                    Api(context, natoGroupClass.get({search: this.table.search})).onSuccess(function(response) {
                        context.table.total = response.data.data.data.total;
                        context.table.data = response.data.data.data.data;
                        context.onLoad = false;
                        context.showTable = true;
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.table.data = [];
                            context.table.total = 0;
                            context.onLoad = true;
                            context.showTable = false;
                        }
                    }).call()
                // }
            },
            filter() {
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    if (this.table.search == '') {
                        this.onLoad = true;
                        this.showTable = false;
                    } else {
                        this.onLoad = true;
                        this.showTable = true;
                    }
                    this.get()
                    this.definition_eng = '';
                    this.definition_ind = '';
                }, 1000);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_nato');
                this.form.show = true;
                this.natoGroupClass = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, natoGroupClass.show(id)).onSuccess(function(response) {
                    context.natoGroupClass = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_nato');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.showTableing = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, natoGroupClass.create(this.natoGroupClass));
                } else {{
                    api = Api(context, natoGroupClass.update(this.natoGroupClass.id, this.natoGroupClass));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.showTableing = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, natoGroupClass.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
        }   
    };
</script>
<style></style>
    